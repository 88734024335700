import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../assets/css/Bookings.css';
import Logo from '../assets/img/TechblumeLogo.png';
import axios from 'axios';
import Footer from './Footer';
import { Link, useNavigate } from 'react-router-dom';

const Loader = () => (
  <div className="loader-overlay">
    <div className="loader"></div>
    <p style={{fontSize:'18px',fontWeight:500,color:'#fff',margin:'1rem 0',textAlign:'center'}}>Please wait while we process your booking..</p>
  </div>
);

const Bookings = () => {
  const [formData, setFormData] = useState({
    from_name: '',
    from_email: '',
    form_countryCode: '1',
    form_phone: '',
    from_timezone: '',
    form_time: null,
    form_date: null,
  });
  
  const [loading, setLoading] = useState(false); // Loading state
  const form = useRef();
  const navigate=useNavigate()

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  const navigateData=()=>{
    const data={
      name:formData.from_name,
      email:formData.from_email,
      date:formData.form_date ,
      time:formData.form_time,
      zone:formData.from_timezone
    }

    navigate('/thank-you/booked-slot', { state: data });
  }



  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    const bookingData = {
      name: formData.from_name || null,
      email: formData.from_email || null,
      phone: formData.form_countryCode && formData.form_phone
        ? `${formData.form_countryCode} ${formData.form_phone}`
        : null,
      zone: formData.from_timezone || 'UTC',
      time: formData.form_time || new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }),
      date: formData.form_date 
        ? new Date(formData.form_date).setUTCHours(0, 0, 0, 0) 
        : new Date().setUTCHours(0, 0, 0, 0),
      leadSource: 'website_booking_form',
      message: '',
      status: 'Booked',
      paymentStatus: 'Not Paid',
      thirdParty: 'No Contract Partner',
      remarks: 'Not yet contacted',
      bookedDate: new Date(),
      updatedBy: 'No update',
    };
    
    try {
      const response = await axios.post('https://techblume-backendcrm.onrender.com/api/bookings/add', bookingData);
   navigateData()      
console.log(response.data);

      // Reset form data
      setFormData({
        from_name: '',
        from_email: '',
        form_countryCode: '1',
        form_phone: '',
        from_timezone: '',
        form_time: null,
        form_date: null,
      });

    } catch (error) {
      console.error('Error creating booking', error);
      alert("We're sorry! We couldn't book your slot at this time. Please reach out to us at support@techblume.in or call +1 3029478773 to confirm your booking.");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Techblume | Slot Booking for Meeting</title>
      </Helmet>

      {loading && <Loader />} {/* Show loader when loading */}

      <div className='Booking-page'>
        <div className='booking-section1'>
          <Link to='/'><img src={Logo} alt="logo" /></Link>
          <section>
            <h2>Interview for Software Developer Training</h2>
            <p>Master Web Development or Data Science with Expert Training</p>
          </section>
        </div>

        <div className="booking-section2">
          <form ref={form} onSubmit={handleSubmit}>
            <h1>Apply Now</h1>
            <p>Open the door to endless opportunities in technology</p>
            
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="from_name"
                value={formData.from_name}
                onChange={handleChange}
                placeholder="Enter your name"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="from_email"
                value={formData.from_email}
                onChange={handleChange}
                placeholder="Enter your email"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="phone">Phone</label>
              <div className="phone-input">
                <select
                  name="form_countryCode"
                  value={formData.form_countryCode}
                  onChange={handleChange}
                  required
                >
                  <option value="1">+1 (US)</option>
                  <option value="44">+44 (UK)</option>
                  <option value="971">+971 (UAE)</option>
                  <option value="973">+973 (Bahrain)</option>
                  <option value="55">+55 (Brazil)</option>
                  <option value="91">+91 (India)</option>
                  <option value="27">+27 (South Africa)</option>
                  <option value="234">+234 (Nigeria)</option>

                </select>
                <input
                  type="tel"
                  id="phone"
                  name="form_phone"
                  value={formData.form_phone}
                  onChange={handleChange}
                  placeholder="Enter your number"
                  required
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="date">Date</label>
              <input
                type="date"
                id="date"
                name="form_date"
                value={formData.form_date}
                onChange={handleChange}
                required
              />
            </div>


            <div className="form-group">
              <label htmlFor="timezone">Time Zone</label>
              <select
                id="timeZone"
                name="from_timezone"
                value={formData.from_timezone}
                onChange={handleChange}
                required
              >
                <option value="">Select your time zone</option>
                <option value="US/Eastern">US/Eastern</option>
                <option value="US/Central">US/Central</option>
                <option value="US/Mountain">US/Mountain</option>
                <option value="US/Pacific">US/Pacific</option>
                <option value="Asia/Bahrain">Asia/Bahrain</option>
                <option value="Asia/Dubai">Asia/Dubai</option>
                <option value="US/Central">US/Central</option>
<option value="US/Mountain">US/Mountain</option>
<option value="America/Sao_Paulo">America/Sao_Paulo</option>
<option value="America/Manaus">America/Manaus </option>
<option value="America/Rio_Branco">America/Rio_Branco </option>
<option value="America/Noronha">America/Noronha </option>

                <option value="Asia/Kolkata">Asia/Kolkata</option>
                <option value="Europe/London">Europe/London</option>
                <option value="Africa/Johannesburg">Africa/Johannesburg</option>
                <option value="Africa/Lagos">Africa/Lagos</option>


              </select>
            </div>

            <div className="form-group">
              <label htmlFor="time">Time</label>
              <input
                type="time"
                id="time"
                name="form_time"
                value={formData.form_time}
                onChange={handleChange}
                required
              />
            </div> 
         

            <button type="submit" className="submit-btn">Submit</button>
          </form>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Bookings;
